.box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  width: 100%;
  border-radius: 4px;
  cursor: pointer;
}


.boxWrapper {
  position: relative;
  width: 100%;
}
.box__text {
  position: absolute;
  top:-16px;
  left: 12px;
  font-size: 12px;
  font-weight: 400;
  color: #9b9b9b;
  font-family: "Poppins", sans;
}

.boxInput {
  width: 100%;
  border-radius: 4px;
  border: 1px solid #E6E6E6;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 1px;
  color: #2F3567;
  font-family: "Poppins", sans;
  padding: 12px;
  background: transparent;
}

.dateInput:focus,
.dateInput:valid {
  color: #808080;
}

.placeholder {
  position: absolute;
  left: 8px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 14px;
  line-height: 14px;
  color: #9b9b9b;
  font-family: "Poppins", sans;
  padding: 0 52px 2px 0;
  background: #FFFF;
}

.dateInput:focus+.placeholder,
.dateInput:valid+.placeholder {
  display: none;
}