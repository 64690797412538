.box {
  font-family: "Poppins", sans;
  color: #2F3567;
  font-size: 14px;
  line-height: 14px;
  border-radius: 4px;
  border: 1px solid #E6E6E6;
  padding: 12px;
  cursor: pointer;
  background-color: #ffff;
  width: 100%;
}


.box__option {
  font-family: "Poppins", sans;
  color: #808080;
  font-size: 14px;
  line-height: 14px;
}


.box__option:checked {
  background-color: #E6E6E6;
}