.box {
  display: flex;
  column-gap: 60px;
  align-items: center;
  justify-content: space-between;
}


.box__content {
  width: 50%
}
