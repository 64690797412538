.box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.box__btn {
  color: #FFFFFF;
  font-size: 12px;
  line-height: 12px;
  font-weight: 500;
  font-family: "Poppins", sans;
  background-color: #46CD80;
  padding: 12px;
  border-radius: 4px;
  cursor: pointer;
}

.box__btn__add {
  color: #FFFFFF;
  font-size: 12px;
  line-height: 12px;
  font-weight: 500;
  font-family: "Poppins", sans;
  background-color: #FF6F3F;
  padding: 12px 24px;
  border-radius: 4px;
  cursor: pointer;
}