.container {
  padding: 0 20px;
}



.container__outlet {
  display: flex;
  column-gap: 12px;
  margin-top: 100px;
}



.container__outlet__box {
  margin-left: 240px;
  flex-grow: 1;

}