.form__group__upload {
  display: flex;
  column-gap: 8px;
  align-items: center;
  border-radius: 4px;
  border: 1px dashed #00A267;
  padding: 4px;
  background-color: #EDFEF8;
}

.form__field__upload__title {
  font-size: 12px;
  color: #808080;
  font-family: "Poppins", sans;
  font-weight: 400;
  padding-bottom: 4px;

}

.form__group__upload__box {
  position: relative;
  display: flex;
  flex-direction: column;

}




.form__field__upload__label {
  display: flex;
  column-gap: 8px;
  align-items: center;
  background-color:#00A267;
  border-radius: 4px;
  cursor: pointer;
  padding: 4px 8px;

}
.upload__img {
  display: block;
  width: 14px;
  height: 14px;
}

.upload__label {
  width: max-content;
  font-size: 12px;
  color: #FFFFFF;
  font-family: "Poppins", sans;
  font-weight: 400;

}


.form__field__upload {
  display: none;

}

.form__group__upload {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.uploadedUrl {
  font-size: 12px;
  color: #808080;
  font-family: "Poppins", sans;
  font-weight: 400;
  word-break: break-all;
}
