.box {
  display: flex;
 justify-content: space-between;
  align-items: center;
}
.box__content {
  width: 30%;
  display: flex;
  justify-content: space-between;
}

.box__btn {
  color: #FFFFFF;
  font-size: 12px;
  line-height: 12px;
  font-weight: 500;
  font-family: "Poppins", sans;
  background-color: #46CD80;
  padding: 12px;
  border-radius: 4px;
  cursor: pointer;
}

