.container {
  position: fixed;
  top: 0px;
  right: 0px;
  left: 0px;
  z-index: 9;
  padding:20px;
  background-color: #F5F6FA
}

.container__box {
  display: flex;
  column-gap: 12px;
  align-items: center;
  background-color: #FAFAFA;
  border-radius: 8px;
  padding: 12px
}


.container__box__logo {
  width: 142px;
  height: 36px;
  cursor: pointer;
}

.container__box__img {
  width: 100%;
  height: 100%;
  object-fit: cover;

}