.box {
  display: flex;
  column-gap: 20px;
  align-items: center;
  justify-content: space-between;
}


.box__content {
  display: flex;
  column-gap: 60px;
  align-items: center;
  width: 30%;
  
}


.box__btn {
  color: #FFFFFF;
  font-size: 14px;
  line-height: 14px;
  font-weight: 500;
  font-family: "Poppins", sans;
  background-color: #FF6F3F;
  padding: 11px 12px;
  border-radius: 4px;
  cursor: pointer;

}