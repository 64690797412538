.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 10;
}

.box {
  position: fixed;
  top: 40%;
  left: 24%;
  right: 6%;
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  padding: 24px;
  border-radius: 8px;
  background-color: #FFFFFF;
  z-index: 11;
}

.form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 20px;
}

.form__title {
  color: #2F3567;
  font-size: 18px;
  line-height: 18px;
  font-weight: 500;
  font-family: "Poppins", sans;

}






.form__group__box {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Three columns of equal width */
  grid-template-rows: repeat(4, auto);   /* Three rows with auto height */
  gap: 24px; /* Spacing between grid items */
}




.buttonGroup {
  display: flex;
  justify-content: space-between;
  align-items: center;
}


.buttonGroup__cancel {
  color: #FE0000;
  font-size: 16px;
  line-height: 16px;
  font-weight: 400;
  font-family: "Poppins", sans;
  padding: 12px;
  cursor: pointer;


}

.buttonGroup__submit {
  color: #FFFFFF;
  font-size: 16px;
  line-height: 16px;
  font-weight: 400;
  font-family: "Poppins", sans;
  padding: 12px 44px;
  background-color: #FFD4C5;
  border-radius: 4px;
  cursor: pointer;
}

.add {
  background-color: #FFD4C5;

}
.isAddActive {
  background-color:#46CD80

}

.delete {
  background-color: #FF6F3F;
}

.isUpdateActive {
  background-color: #FF6F3F;

}