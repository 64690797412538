.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  z-index: 10;
}

.box {
  position: fixed;
  top: 40%;
  left: 50%;
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  transform: translate(16%, -50%);
  padding: 16px 12px;
  border-radius: 8px;
  background-color: #FFF4F0;
  z-index: 11;
}

.form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 20px;
}


.form__controls {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  justify-content: center;
}

.form__group {
  position: relative;
  padding: 15px 0 0;
}

.form__field {
  width: 100%;
  border-radius: 4px;
  border: 1px solid #d2d2d2;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 1px;
  color: #2F3567;
  font-family: "Poppins", sans;
  padding: 12px 20px;
  background: transparent;
}

textarea.form__field {
  min-height: 100px;
  padding: 8px;
  font-size: 12px;
  resize: none;
  resize: none;
}


.form__field::placeholder {
  color: transparent;
}

.form__field:placeholder-shown~.form__label {
  font-size: 16px;
  cursor: text;
  top: 40px;
  background-color: #FFF4F0;
}

.form__label {
  font-family: "Poppins", sans;
  left: 12px;
  padding: 0 12px;
  
}

.form__label,
.form__field:focus~.form__label {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  font-size: 16px;
  color: #9b9b9b;
  background-color: #FFF4F0; 
}

.form__field:focus~.form__label {
  color: #2F3567;
 
}



.buttonGroup {
  display: flex;
  justify-content: space-between;
}


.form__btn {
  color: #FFFFFF;
  font-size: 16px;
  line-height: 16px;
  font-weight: 500;
  font-family: "Poppins", sans;
  background-color: #9C9C9C;
  padding: 12px;
  border-radius: 4px;
  

}

.form__btn__enabled {
  background-color: #FF6F3F;
  cursor: pointer;

}