.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 10;
}

.box {
  position: fixed;
  top: 32%;
  left: 44%;
  right: 24%;
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  padding: 24px;
  border-radius: 8px;
  background-color: #FFFFFF;
  z-index: 11;
}

.form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 12px;
}

.form__title {
  color: #2F3567;
  font-size: 18px;
  line-height: 18px;
  font-weight: 500;
  font-family: "Poppins", sans;

}





.form__group {
  display: flex;
  flex-direction: column;
  row-gap: 8px;

}

.form__group__box {
  display: flex;
  flex-direction: column;
  row-gap: 4px;

}

.form__field__upload__title {
  font-size: 12px;
  color: #808080;
  font-family: "Poppins", sans;
  font-weight: 400;
  padding-bottom: 4px;

}

.form__group__upload {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  border: 1px dashed #E6E6E6;
  padding: 4px;
}




.form__field__upload__label {
  display: block;
  width: 111px;
  height: 24px;
  background-image: url("../../assets//upload.svg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 4px;
  cursor: pointer;

}


.form__field__upload {
  display: none;

}

.form__group__upload {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.uploadedUrl {
  font-size: 10px;
  color: #808080;
  font-family: "Poppins", sans;
  font-weight: 400;
  word-break: break-all;
}


.form__group__seq {
  position: relative;
  /* padding: 12px 0 0; */
}

.form__field {
  width: 100%;
  color: #808080;
  font-size: 14px;
  line-height: 14px;
  border: 1px solid #E6E6E6;
  letter-spacing: 1px;
  border-radius: 4px;
  font-family: "Poppins", sans;
  padding: 8px 4px 4px;
  background: transparent;
}

.form__field::placeholder {
  color: transparent;
}

.form__field:placeholder-shown~.form__label {
  font-size: 14px;
  cursor: text;
  top: 24px;
}

.form__label {
  font-family: "Poppins", sans;
  color: #808080;
  font-size: 14px;
  line-height: 14px;
  left: 8px;
  padding: 0 8px;
}

.form__label,
.form__field:focus~.form__label {
  position: absolute;
  top: 10px;
  display: block;
  transition: 0.2s;
  font-size: 14px;
  color: #808080;
  background-color: #ffff;
}

.form__field:focus~.form__label {
  color: #808080;
}






.buttonGroup {
  display: flex;
  justify-content: space-between;
  align-items: center;
}


.buttonGroup__cancel {
  color: #FE0000;
  font-size: 16px;
  line-height: 16px;
  font-weight: 400;
  font-family: "Poppins", sans;
  padding: 12px;
  cursor: pointer;


}

.buttonGroup__submit {
  color: #FFFFFF;
  font-size: 16px;
  line-height: 16px;
  font-weight: 400;
  font-family: "Poppins", sans;
  padding: 12px 44px;
  background-color: #FF6F3F;
  border-radius: 4px;
  cursor: pointer;
}

